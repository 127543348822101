<template>
  <div>
    <div class="menucategory-wrapper">
      <div v-if="getDisplayCategories.length">
        <ul class="category-chip-list">
          <li
            @click="getCategoryMenu(category.id, category.categoryname)"
            class="category-chip"
            :style="[
              category.id == activeIndex
                ? {
                    background: getStyle.categorybackgroundcolor || 'var(--ion-color-primary)',
                    color: getStyle.textcolor || 'var(--ion-color-primary-contrast)',
                    border: '0px',
                  }
                : {
                    color: getStyle.categorybackgroundcolor || 'var(--ion-color-primary)',
                    border:
                      '1px solid ' + getStyle.categorybackgroundcolor || 'var(--ion-color-primary)',
                  },
            ]"
            v-for="category in filterCategories"
            :key="category.id"
          >
            {{ capitalize(category.categoryname) }}
          </li>
        </ul>
      </div>
      <!-- Loader -->
      <div v-if="!getDisplayCategories.length">
        <ul class="category-chip-list">
          <li class="category-chip loader-chip" v-for="category in loaderItems" :key="category.id">
            <ion-skeleton-text animated></ion-skeleton-text>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-wrapper">
      <ion-list>
        <div v-if="getDisplayMenu.length">
          <!-- Check if Category name is Deals when deal category is clicked -->
          <div v-if="categoryName == 'deals'">
            <div v-for="(subs, index) in filterMenuDeals" :key="index">
              <ion-list>
                <ion-item v-for="item in subs" :key="item.id">
                  <ion-thumbnail v-if="item.thumbnail" slot="end">
                    <img :src="item.thumbnail" />
                  </ion-thumbnail>
                  <div class="item-wrapper">
                    <ion-label class="item-name">{{ item.menuitemname }}</ion-label>
                    <div>
                      <div class="deal" v-if="item.dealid">
                        <div class="deal-wrapper">
                          <ion-icon :src="pricetagOutline"></ion-icon>
                          <ion-label>{{ item.discount }}% Off</ion-label>
                        </div>
                      </div>
                    </div>
                    <span class="item-description" v-if="item.description">{{
                      item.description
                    }}</span>
                    <div class="price-wrapper">
                      <div class="multisize" v-if="item.sizeoption == 'multisize'">
                        <div class="deal-price" v-if="item.dealid && item.discount > 0">
                          <span
                            class="original-price"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} : {{ filterCurrency(size.price) }}</span
                          >
                          <span
                            :style="{ color: getStyle.categorybackgroundcolor }"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} :
                            {{ addDiscount(size.price, item.discount) }}</span
                          >
                        </div>
                        <div v-else>
                          <span
                            :style="{ color: getStyle.categorybackgroundcolor }"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} : {{ filterCurrency(size.price) }}</span
                          >
                        </div>
                      </div>
                      <div class="singlesize" v-else>
                        <div class="deal-price" v-if="item.dealid && item.discount > 0">
                          <span class="original-price">{{ filterCurrency(item.price) }}</span>
                          <span :style="{ color: getStyle.categorybackgroundcolor }">{{
                            addDiscount(item.price, item.discount)
                          }}</span>
                        </div>
                        <div v-else>
                          <span :style="{ color: getStyle.categorybackgroundcolor }">{{
                            filterCurrency(item.price)
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
          <!-- Category name is not deal ie deal category not clicked -->
          <div v-else>
            <div v-for="(subs, index) in filterMenuSubs" :key="index">
              <div
                v-if="index != 'default'"
                class="subcategory-label"
                :style="{
                  background: getStyle.subcategorybackgroundcolor,
                  color: getStyle.textcolor,
                  'word-wrap': 'break-word',
                  'white-space': 'normal',
                }"
              >
                <ion-label>{{ capitalize(index) }}</ion-label>
              </div>
              <ion-list>
                <ion-item v-for="item in subs" :key="item.id">
                  <ion-thumbnail
                    @click="openDialog(item.thumbnail, $event)"
                    v-if="item.thumbnail"
                    slot="end"
                  >
                    <img :src="item.thumbnail" />
                  </ion-thumbnail>
                  <div class="item-wrapper">
                    <ion-label class="item-name">{{ item.menuitemname }}</ion-label>
                    <div>
                      <div class="deal" v-if="item.dealid">
                        <div class="deal-wrapper">
                          <ion-icon :src="pricetagOutline"></ion-icon>
                          <ion-label>{{ item.discount }}% Off</ion-label>
                        </div>
                      </div>
                    </div>
                    <span class="item-description" v-if="item.description">{{
                      item.description
                    }}</span>
                    <div class="price-wrapper">
                      <div class="multisize" v-if="item.sizeoption == 'multisize'">
                        <div class="deal-price" v-if="item.dealid && item.discount > 0">
                          <span
                            class="original-price"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} : {{ filterCurrency(size.price) }}</span
                          >
                          <span
                            :style="{ color: getStyle.categorybackgroundcolor }"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} :
                            {{ addDiscount(size.price, item.discount) }}</span
                          >
                        </div>
                        <div v-else>
                          <span
                            :style="{ color: getStyle.categorybackgroundcolor }"
                            v-for="(size, index) in item.size"
                            :key="index"
                            >{{ capitalize(size.name) }} : {{ filterCurrency(size.price) }}</span
                          >
                        </div>
                      </div>
                      <div class="singlesize" v-else>
                        <div class="deal-price" v-if="item.dealid && item.discount > 0">
                          <span class="original-price">{{ filterCurrency(item.price) }}</span>
                          <span :style="{ color: getStyle.categorybackgroundcolor }">{{
                            addDiscount(item.price, item.discount)
                          }}</span>
                        </div>
                        <div v-else>
                          <span :style="{ color: getStyle.categorybackgroundcolor }">{{
                            filterCurrency(item.price)
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </div>
        <!-- Loader -->
        <div v-if="!getDisplayMenu.length">
          <div v-for="item in loaderItems" :key="item.id">
            <div class="subcategory-label">
              <ion-skeleton-text animated></ion-skeleton-text>
            </div>
            <ion-list>
              <ion-item v-for="item in loaderItems" :key="item.id">
                <ion-thumbnail slot="end">
                  <ion-skeleton-text animated></ion-skeleton-text>
                </ion-thumbnail>
                <div class="item-wrapper loader">
                  <!-- <div v-if="item.id == getDeals."></div> -->
                  <ion-skeleton-text animated></ion-skeleton-text>
                  <span class="item-description">
                    <ion-skeleton-text animated></ion-skeleton-text
                  ></span>
                  <div class="price-wrapper">
                    <div class="multisize loader">
                      <span> <ion-skeleton-text animated></ion-skeleton-text></span>
                    </div>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </ion-list>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import {
    IonList,
    IonItem,
    IonLabel,
    IonThumbnail,
    IonSkeletonText,
    IonIcon,
    popoverController,
  } from '@ionic/vue'
  import ImagePopOver from '../components/util/ImagePopOver.vue'
  import { pricetagOutline } from 'ionicons/icons'
  export default {
    name: 'Menu',
    emits: ['categoryName'],
    props: {
      currentProperties: {
        type: String,
        required: false,
      },
    },
    components: {
      IonList,
      IonItem,
      IonLabel,
      IonThumbnail,
      IonSkeletonText,
      IonIcon,
    },
    data() {
      return {
        isActive: false,
        activeIndex: null,
        categoryName: '',
        subCategory: '',
        loaderItems: [1, 2, 3],
        loading: true,
        pricetagOutline,
      }
    },
    methods: {
      ...mapActions({}),
      async openDialog(imagelink, event) {
        const popover = await popoverController.create({
          component: ImagePopOver,
          showBackdrop: true,
          backdropDismiss: true,
          cssClass: 'img-popover',
          event: event,
          componentProps: {
            imagelink: imagelink,
          },
        })
        popover.present()
      },
      capitalize(data) {
        let capitalized = []
        data.split(' ').forEach((word) => {
          capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        })
        return capitalized.join(' ')
      },
      filterCurrency(data) {
        const prefix = 'KSh '
        let localizedCurrency = data.toLocaleString('en-KE')
        let currency = prefix.concat(localizedCurrency)
        return currency
      },
      addDiscount(currentPrice, discount) {
        let finalDiscount = 100 - parseFloat(discount)
        let finalPrice = Math.round(parseFloat(finalDiscount / 100) * parseFloat(currentPrice))
        return this.filterCurrency(finalPrice)
      },
      getCategoryMenu(id, name) {
        this.activeIndex = id
        this.categoryName = name
      },
    },
    created() {
      if (!this.activeIndex && !this.categoryName && this.complexMenu) {
        this.activeIndex = this.filterCategories[0].id
        this.categoryName = this.filterCategories[0].categoryname
      }
    },
    computed: {
      ...mapGetters(['getDisplayCategories', 'getDisplayMenu', 'getDeals', 'getStyle', 'getError']),
      complexMenu: function() {
        return this.$store.state.complexmenu
      },
      filterCategories: function() {
        // Check for the categories that match the major category name passed as props
        let filteredCategories = []
        if (this.complexMenu) {
          filteredCategories = this.getDisplayCategories.filter((category) =>
            category.majorcategories.includes(this.currentProperties)
          )
        } else {
          filteredCategories = this.getDisplayCategories
        }
        if (this.getDeals.length) {
          let singleCategory = {
            categoryname: 'deals',
            id: 'deals',
          }
          filteredCategories.push(singleCategory)
        }
        return filteredCategories
      },
      filterMenuSubs: function() {
        let newMenu = []
        if (this.categoryName != '') {
          // Filter menu based on the category name, active status & availability
          const filteredMenu = this.getDisplayMenu.filter(
            (menuItem) =>
              menuItem.categories.includes(this.categoryName) &&
              menuItem.active == 'active' &&
              menuItem.available == 'available'
          )
          filteredMenu.forEach((menuItem) => {
            const item = this.getDeals.find((item) => item.dealitems.includes(menuItem.id))
            if (item != undefined) {
              menuItem.dealid = item.id
              menuItem.dealtype = item.dealtype
              menuItem.discount = item.discount
            }
          })
          // Group the filtered menu using the sub categories (grouped using the reduce array function)
          newMenu = filteredMenu.reduce(function(prev, curr) {
            let key = curr.subcategories[0]
            if (!prev[key]) {
              prev[key] = []
            }
            prev[key].push(curr)
            return prev
          }, {})
        }
        if (this.categoryName == '' && this.complexMenu == false) {
          // Group the filtered menu using the sub categories (grouped using the reduce array function)
          const filteredMenu = this.getDisplayMenu.filter(
            (menuItem) => menuItem.active == 'active' && menuItem.available == 'available'
          )
          newMenu = filteredMenu.reduce(function(prev, curr) {
            let key = curr.subcategories[0]
            if (!prev[key]) {
              prev[key] = []
            }
            prev[key].push(curr)
            return prev
          }, {})
        }
        return newMenu
      },
      filterMenuDeals: function() {
        let newMenu = []
        let menuWithDeals = []
        if (this.categoryName == 'deals') {
          // Filter menu based on the category name, active status & availability
          const filteredMenu = this.getDisplayMenu.filter(
            (menuItem) => menuItem.active == 'active' && menuItem.available == 'available'
          )
          filteredMenu.forEach((menuItem) => {
            const item = this.getDeals.find((item) => item.dealitems.includes(menuItem.id))
            if (item != undefined) {
              menuItem.dealid = item.id
              menuItem.dealtype = item.dealtype
              menuItem.discount = item.discount
              menuWithDeals.push(menuItem)
            }
          })
          // Group the filtered menu using the sub categories (grouped using the reduce array function)
          newMenu = menuWithDeals.reduce(function(prev, curr) {
            let key = curr.subcategories[0]
            if (!prev[key]) {
              prev[key] = []
            }
            prev[key].push(curr)
            return prev
          }, {})
        }
        return newMenu
      },
    },
    watch: {
      filterCategories: {
        immediate: true,
        handler: function(params) {
          if (!this.activeIndex && !this.categoryName) {
            this.activeIndex = params[0].id
            this.categoryName = params[0].categoryname
          }
        },
      },
    },
  }
</script>
<style lang="scss" scoped>
  .menucategory-wrapper {
    padding: 0.5rem;
    background-color: lighten($color: #f4f5f8, $amount: 2);
    ul.category-chip-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      .category-chip {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid var(--ion-color-primary);
        padding: 0.45rem 0.5rem;
        margin: 0.15rem;
        border-radius: 50px;
        font-size: 0.75em;
        color: var(--ion-color-primary);
        font-weight: 500;
        text-align: center;
        transition: 0.2s ease;
        &:hover {
          cursor: pointer;
          // background-color: currentColor;
          color: #ffffff;
        }
      }
    }
  }
  .menu-wrapper {
    .subcategory-label {
      background-color: var(--ion-color-warning);
      padding: 0.5rem;
      font-size: 0.9em;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .item-wrapper {
      .item-name {
        word-wrap: break-word;
        white-space: normal;
      }
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0 0 0;
      .deal {
        background: var(--ion-color-warning);
        display: inline-block;
        margin: 0.2rem 0;
        padding: 0.3rem 0.5rem;
        border-radius: 7px;
        .deal-wrapper {
          display: flex;
          ion-label {
            font-size: 0.8rem;
            margin-left: 0.2rem;
          }
        }
      }
      .item-description {
        font-size: 0.75em;
        line-height: 1.5;
        color: var(--ion-color-medium);
      }
      .price-wrapper {
        padding: 0.5rem 0.5rem 0.5rem 0;
        .multisize {
          div {
            display: flex;
            flex-direction: column;
            span {
              font-size: 0.9em;
              line-height: 1.5;
              font-weight: 500;
              color: var(--ion-color-primary-shade);
            }
          }
        }
        .singlesize {
          span {
            font-size: 0.9em;
            line-height: 1.5;
            font-weight: 500;
            color: var(--ion-color-primary-shade);
          }
        }
      }
    }
    ion-thumbnail {
      img {
        border-radius: 7px;
      }
    }
  }
  .loader {
    display: block;
    width: 100%;
    ion-skeleton-text {
      display: flex;
      width: 80%;
    }
  }
  .loader-chip {
    width: 80px;
  }
  .deal-price {
    display: flex;
    .original-price {
      margin-right: 0.4rem;
      text-decoration: line-through;
      color: #000000 !important;
    }
  }
</style>
