<template>
  <ion-content>
    <ion-img :src="imagelink"></ion-img>
  </ion-content>
</template>
<script>
  import { IonContent, IonImg } from '@ionic/vue'
  export default {
    name: 'ImagePopOver',
    props: ['imagelink'],
    components: {
      IonContent,
      IonImg,
    },
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  ion-content {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
  ion-img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
</style>
